<script>
import {defineComponent} from "vue";
import axios from "axios";
import ConvertOrder from "@/views/pages/pre-orders/modals/ConvertOrder.vue";
import ConvertWagonOrder from "@/views/pages/pre-orders/modals/ConvertWagonOrder.vue";
import ConvertEmptyWagonOrder from "@/views/pages/pre-orders/modals/ConvertEmptyWagonOrder.vue";
import NoData from "@/components/custom/NoData.vue";
import moment from "moment";
import Swal from "sweetalert2";

export default defineComponent({
  name: "UnderReviewTab",
  components: {
    NoData,
    ConvertOrder,
    ConvertWagonOrder,
    ConvertEmptyWagonOrder,
  },
  data() {
    return {
      pre_orders: [],
      preOrderId: 0,
      pre_order_container_id: 0,
      pre_order_wagon_id: 0,
      pre_order_empty_wagon_id: 0,
      is_loading: true,
    };
  },
  methods: {
    downloadFile(filepath) {
      let url = process.env.VUE_APP_ORDER_URL + filepath;
      fetch(url)
          .then((resp) => resp.blob())
          .then((blobobject) => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement("a");
            anchor.style.display = "none";
            anchor.href = blob;
            anchor.download = url.toString().split("/")[
            url.toString().split("/").length - 1
                ];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
            this.is_downloading_file = false;
          })
          .catch(() => alert("An error in downloading the file sorry"));
    },
    async getPreOrders() {
      try {
        this.is_loading = true;
        let response = await axios.get("/pre_order/list/under_review/");
        this.pre_orders = response.data.results;
      } catch {
        this.pre_orders = [];
      }
      this.is_loading = false;
    },
    getModalId(loading_type) {
      if (loading_type === "container") {
        return "#ConvertOrderTestModal";
      } else if (loading_type === "wagon") {
        return "#ConvertWagonOrderModal";
      } else if (loading_type === "wagon_empty") {
        return "#ConvertEmptyWagonOrderModal";
      }
    },
    openModal(order_id, loading_type) {
      if (loading_type === "container") {
        this.pre_order_container_id = order_id;
      } else if (loading_type === "wagon") {
        this.pre_order_wagon_id = order_id;
      } else if (loading_type === "wagon_empty") {
        this.pre_order_empty_wagon_id = order_id;
      }
    },
    formatDate(date) {
      if (!date) return '-'
      return moment(date).format('lll');
    },
    getOrderEditUrl(loading_type) {
      if (loading_type === "container") {
        return "pre_orders_container_update";
      } else if (loading_type === "wagon") {
        return "pre_orders_wagon_update"
      } else {
        return "pre_orders_empty_wagon_update"
      }
    },

    cancelOrderConfirmation(order) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `You are about to cancel this order`,
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Yes, Cancel it',
        denyButtonText: 'No, Keep it',
        cancelButtonColor: 'transparent',
        focusCancel: true,
        input: 'text',
        inputLabel: `Please type YES to confirm`,
        inputPlaceholder: `YES`,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value === 'YES') {
              resolve(this.cancelOrder(order))
            } else {
              resolve('Confirmation did not match :)')
            }
          })
        }
      });
    },
    async cancelOrder(order) {
      try {
        await axios.put(`/pre_order/cancel/${order.id}/`);
        await this.getPreOrders();
        await Swal.fire({
          position: "center",
          icon: "success",
          title: `Order canceled successfully`,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch {
        await Swal.fire({
          icon: "error",
          title: "An error occurred",
        });
      }
    }
  },
  created() {
    this.getPreOrders();
  },
});
</script>

<template>
  <ConvertOrder :preOrderId="pre_order_container_id" @created="getPreOrders()"/>
  <ConvertWagonOrder :preOrderId="pre_order_wagon_id" @created="getPreOrders()"/>
  <ConvertEmptyWagonOrder :preOrderId="pre_order_empty_wagon_id" @created="getPreOrders()"/>

  <b-spinner v-if="is_loading" variant="warning"></b-spinner>

  <b-row v-else-if="!is_loading">
    <template v-if="pre_orders.length > 0">
      <b-col sm="12" md="6" lg="4" xxl="3" v-for="order in pre_orders" :key="order">
        <b-card no-body>
          <b-card-body class="pb-0">
            <div class="d-flex mb-3">
              <div class="flex-shrink-0 avatar-sm">
                <div class="avatar-title bg-light rounded">
                  <img src="@/assets/images/companies/img-8.png" alt="" class="avatar-xxs"/>
                </div>
              </div>
              <div class="flex-grow-1 ms-3" style="max-width: 220px">
                <h5 class="fs-15 mb-1 text-truncate">
                  {{ order.company.name }}
                </h5>
                <p class="text-muted mb-2">Customer</p>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-borderless mb-0 table-sm">
                <tbody>
                <tr class="align-middle">
                  <td scope="row">
                      <span class="me-1">
                        <i class="mdi mdi-truck-outline fs-16 align-middle"></i>
                      </span>
                    <span class="text-muted">Loading Type</span>
                  </td>
                  <th class="text-end">
                    {{ order.loading_type ? order.loading_type.replace(/_/g, " ").toUpperCase() : "" }}
                  </th>
                </tr>
                <tr class="align-middle">
                  <td scope="row">
                      <span class="me-1">
                        <i class="bx bx-message-detail fs-18 align-middle" style="transform: translateX(-3px)"></i>
                      </span>
                    <span class="text-muted">Quantity</span>
                  </td>
                  <th class="text-end">
                    {{ order.quantity ? order.quantity : "--" }}
                  </th>
                </tr>
                <tr class="align-middle">
                  <td scope="row">
                      <span class="text-warning me-1">
                        <i class="bx bx-dollar-circle fs-18 align-middle" style="transform: translateX(-3px)"></i>
                      </span>
                    <span class="text-muted">Agreed Rate</span>
                  </td>
                  <th class="text-end">
                    ${{ order.agreed_rate ? order.agreed_rate : 0 }}
                  </th>
                </tr>
                <tr class="align-middle">
                  <td scope="row">
                      <span class="text-danger me-1">
                        <font-awesome-icon :icon="['fas', 'location-dot']"/>
                      </span>
                    <Span class="text-muted">Departure</Span>
                  </td>
                  <th class="text-end">
                    {{ order.departure ? order.departure.name : "--" }}
                  </th>
                </tr>
                <tr class="align-middle">
                  <td scope="row">
                      <span class="text-success me-1">
                        <font-awesome-icon :icon="['fas', 'location-dot']"/>
                      </span>
                    <span class="text-muted">Destination</span>
                  </td>
                  <th class="text-end">
                    {{ order.destination ? order.destination.name : "--" }}
                  </th>
                </tr>
                <tr class="align-middle">
                  <td scope="row">
                      <span class="text-success me-1">
                        <font-awesome-icon :icon="['fas', 'location-dot']"/>
                      </span>
                    <span class="text-muted">Territories</span>
                  </td>
                  <th class="text-end">
                    {{
                      order.territories
                          ? order.territories.map((t) => t.name).join(", ")
                          : "--"
                    }}
                  </th>
                </tr>
                <tr class="align-middle">
                  <td scope="row">
                      <span class="text-secondary me-1">
                        <font-awesome-icon :icon="['fas', 'calendar-day']"/>
                      </span>
                    <span class="text-muted">Created At</span>
                  </td>
                  <th class="text-end">
                    {{
                      formatDate(order.created)
                    }}
                  </th>
                </tr>
                </tbody>
              </table>
            </div>
          </b-card-body>
          <b-card-body class="border-top border-top-dashed">
            <div class="d-flex gap-3">
              <b-button size="sm" variant="outline-danger" class="w-100" @click="cancelOrderConfirmation(order)">
                <i class="ri-close-line align-bottom"></i>
                Cancel
              </b-button>
              <router-link class="w-100"
                           :to="{ name: `${getOrderEditUrl(order.loading_type)}`, params: { id: order.id } }">
                <b-button size="sm" variant="outline-success" class="w-100">
                  <i class="ri-pencil-fill align-bottom"></i>
                  Edit
                </b-button>
              </router-link>
              <b-button @click="openModal(order.id, order.loading_type)" data-bs-toggle="modal"
                        :data-bs-target="getModalId(order.loading_type)" size="sm" class="w-100">
                Approve
              </b-button>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </template>
    <template v-else>
      <NoData>No Under Review Orders Found</NoData>
    </template>
  </b-row>
</template>

<style scoped></style>
