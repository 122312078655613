<script>
import {defineComponent} from 'vue'
import axios from "axios"
import {emptyWagonOrderComputed, emptyWagonOrdersMethods} from "@/state/helpers"
import EmptyWagonOrderCreate from "@/views/pages/orders/empty_wagon/create/Create.vue"

export default defineComponent({
  name: "ConvertOrder",
  emits: ['created'],
  props: {
    preOrderId: {
      type: Number || null,
      required: true
    }
  },
  components: {
    EmptyWagonOrderCreate
  },
  data() {
    return {
      order: {}
    }
  },
  computed: {
    ...emptyWagonOrderComputed
  },
  methods: {
    ...emptyWagonOrdersMethods,
    async getPreOrderInfo() {
      try {
        let response = await axios.get(`/pre_order/list/${this.preOrderId}/`)
        this.order = response.data
        this.autocompleteOrder()
      } catch {
        this.order = {}
      }
    },
    autocompleteOrder() {
      this.fullClean()

      this.setPosition('rail_forwarder')
      this.setShipper(this.order.shipper)
      this.setConsignee(this.order.consignee)
      this.setDate(this.order.date)


      this.setAutoComplete({
        departures: this.order.departure,
        destinations: this.order.destination,
        companies: this.order.company,
        products: this.order.product,
      })

      //
      this.setDepartureId(this.order.departure.id)
      this.setDestinationId(this.order.destination.id)
      //
      this.setBorderCrossing(this.order.border_crossing)
      this.setConditionsOfCarriage(this.order.conditions_of_carriage)
      this.setRollingStock(this.order.rolling_stock_1 + '-' + this.order.rolling_stock_2)
      this.setDeparture(this.order.departure_country.name)
      this.setDestination(this.order.destination_country.name)
      this.setCompanyId(this.order.company.id)
      this.setQuantity(this.order.quantity)
      this.setAgreedRate(this.order.agreed_rate)

      this.setPreOrder({
        id: this.order.id,
        type: 'code_order',
        territories: this.order.territories,
      })
    }
  },
  watch: {
    preOrderId: {
      immediate: true,
      handler: async function (val, oldVal) {
        if (val && val !== oldVal) {
          this.order = {}
          await this.getPreOrderInfo()
        }
      }
    }
  }
})
</script>

<template>
  <div id="ConvertEmptyWagonOrderModal"
       class="modal fade" tabindex="-1" aria-hidden="true" style="display: none">
    <div class="modal-dialog modal-xl">
      <div class="modal-content border-0">
        <div class="modal-header border-bottom p-3">
          <h5 class="modal-title">
            Convert to Empty Wagon Order
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <div class="" v-if="Object.keys(order).length > 0">
            <EmptyWagonOrderCreate @created="this.$emit('created')"/>
          </div>

          <div v-else class="text-center py-5">
            <b-spinner></b-spinner>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>